.topbar{
    padding: 0px;
}
.btn-add{
    border: none;
    border-radius: 15px;
    color: white;
    background-color: #696CFF;
}
.btn-add:hover {
    background-color: #5e5e7e; /* Grey color when hovering */
  }