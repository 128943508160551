.multi_select{
    min-height: 50px !important;
    border-radius: 14px;
    background-color: #fff;
    .css-13cymwt-control{
        
        border-radius: 14px;
        min-height: 55px;
    }
    .css-t3ipsp-control{
        min-height: 55px;
    }
    .css-1u9des2-indicatorSeparator{
        background-color: transparent !important;
    }
}
// .css-b62m3t-container{
// width: 450px !important;
// }
.new-label{
    border-radius: 30px!important;
}