.backgroundimage {
    min-height: 100%;
    // width: 105px;
    background-repeat: no-repeat;
    // background-repeat: round;
}

.upload-image {
    align-items: center;
    display: flex;
    justify-content: center;
}

.avatarPreview {
    // width: 250px;
    height: 150px;
    position: relative;
    border-radius: 5px;
    border: 2px solid #555fe67a;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
}
.imagePreview {
    border: 2px solid #555fe67a;
    min-height: 150px;
    border-radius: 16px;
    overflow: hidden;
}
.addcustomimage {
    color: transparent !important;
}
.up-btn{
    background-color: #696cff;
    color: #fff;
}
.breadcrumb{
    color: #515862;
}
