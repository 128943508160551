// @import "bootstrap/dist/css/bootstrap.min.css";
// body {
//     background-color: #f4f4f4;
//     color: #f4f4f4;
//   }
  
.login-card-parent{
  min-height: 100vh;
}

.login-card {
  min-width:100%;
  background-color: #fff;
  border: 1px solid #8f90bd;
  border-radius: 30px;
}

// .login-btn {
//  width: 450px;
// }

.image-login {
  max-height: 80vh;
  border-radius: 0px 30px 30px 0px;
  margin-left: 134px;
}


.para-login {
  font-weight: 500;
  color: #515862;
}



// .login-container {
//   background-color: blue;
//   color: aliceblue;
// }

.client-para {
  font-size: 20px !important;
  border: 1px solid black !important;
}

.email,.pasword{
  color:#696CFF;
  font-weight: 500;
}
.e-form,.e-pas{
  
  padding:  13px 0px 14px 15px;
  border-radius: 20px;
}
// .login-btn{

//   border-radius: 30px;
//   color: white;
//   background-color: #696CFF;
//   // padding: 16px 200px 16px 200px;
// }
.login-btn:hover {
  border-radius: 30px;
  color: #fff;
  background-color:#696cff; /* Change the background color on hover */
}
.buttonss{
  border-radius: 30px;
  border: none;
  color: white;
  background-color: #696CFF;
}
.buttonss:hover{
  color: #fff!important;
}
.nlog-b:hover{
  color: #515862;
}
.login-image{
  border-radius: 0px 30px 30px 0px;
  background-image: url(../../../assets/images/login-card.svg);
  background-repeat: no-repeat;
  min-height: 530px;
  background-position: center;
  background-size: cover;

}

.nlog-b{
  border-radius: 20px;
  color: #fff;
  border: none;
  padding:16px 0px 16px 0px ;
}
.sidebar{
  width: 225px;
}
.list-group a:hover{
  cursor: pointer;
  color: #696CFF;
  background-color: rgba(149, 150, 224, 0.2); /* 20% opacity */
}
.form-control{
  width: 430px;
}
.link{
  color: #515862;
}
  .eyebtn{
    color: #515862!important;
    display: block;
    position:absolute
  }
  .eyebtn:hover{
    background-color: #515862;
    color: #515862;
  }