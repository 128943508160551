.add-btn {
    border: none;
    border-radius: 15px;
    padding: 5px 10px;
    background: #696CFF;
    color: #fff;
}

.add-btn:hover {
    background: #5e5e7e;
}

.card-btn {
    min-width: 303px;
}

.btn-add {
    border-radius: 15px !important;
}

@media (max-width: 767px) {
    .btn-add {
        display: flex;
        align-items: center !important;
        justify-content: center !important;
        margin-top: 1rem;
    }
    .col-12{
        justify-content: center !important;
    }
    .search-style{
        padding-left: 0rem !important;
    }
    .search-icon{
        margin-right: -220px;
    }
}