.selecttype{
    width: 80%;
}
.selecttype{
    color: black;
}
// .date-input{
//     width: 450px!important;
// }

@media (max-width: 375px) {
    .btn-add {
      margin-top: 2rem; /* Add some spacing at the bottom */
      margin-left: 5rem;
    }
}




  @media (max-width: 932px) {
    .btn-add {
      /* Your styles for the button */
      margin-top: 2rem !important;
      margin-left: 2rem !important;
    //   margin-right: 3rem !important;
    }
  }
