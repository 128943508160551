// .filtericon{
//     position: absolute;
//     right: 10px;
//     color: rgb(118, 118, 118);
//     z-index: 99999 !important;
// }
.filterinput{
    padding-left: 370px;
}
.btnEdit{
    border: 1px solid #458591;
    border-radius: 4px;
    padding: 10px 22px;
    background: #606cff; 
    color: #fff;
    background-color: transparent;
}
.btnEdit:hover{
    background: #fff;
}


.form-check-input:focus {
    border-color: #fff;
    outline: 0;
    // box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
    background:#515862
}
// .form-check-input:checked[type=radio] {
//     background-image:none !important;
// }
 
// .form-check-input:checked {
//     background-color: #458591; 
//     border-color: #458591; 
// }
.edit-btn{
    border-color:rgba(105, 108, 255, 0.5); /* #696CFF with 50% opacity */
    border-radius: 16px;
    background-color: #fff;
    color: #515862;
    font-size: 15px;
    font-weight: 500;
}
.green-status{
    color: green;
}
.red-status{
    color: red;
}
.photos-btn{
    background-color:rgba(105, 108, 255, 0.1); /* #696CFF with 50% opacity */ ;
    border-radius: 30px;
    color: #515862;
    font-weight: 500;
    font-size: 15px;
    border: none;
}
