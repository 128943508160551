.selecttype2{
    border-radius: 50px;
}
.dropwdonwtoglle{
    border-radius: 16px;
    background-color: #fff;
    border: 1px solid #d5dbe6;
}

.css-13cymwt-control{
    padding-left: 1rem;
    border-color: #fff!important;
    border-radius: 30px !important;
    background-color: #fff !important;
    border: 1px solid #e7e7e7!important;
    outline: none;
}
.css-1hb7zxy-IndicatorsContainer{
    display: block!important;
    border: none !important;
}

@media (max-width: 767px) {
    .css-13cymwt-control {
       margin-top: 1rem;
       margin-right: 1rem;
    }
}

/* Media query for tablets (768px to 1023px) */
@media (min-width: 768px) and (max-width: 1023px) {
    .css-13cymwt-control {
margin-top: 2rem;
    }
}
