.reset-btn{
    border: none;
    border-radius: 30px;
    color: #fff;
    background-color: #696CFF!important;
}
.reset-btn:hover{
    color: #515862;
    background-color: #696CFF!important;
}
.form-control{
    border-radius: 20px;
}
.confirmnew{
    color: #696CFF;
    font-weight: 500;
}